import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
export const PdfHeaderToD10 = ({title, children, order, language}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "." + month + '.' + year;
	const role = storage.read('role')
	return (
		<div className={styles.title + ' ' + styles.print} style={{'margin': '3rem'}}>
			<h3>Отчет Д10</h3>
			{(role === 'librarian' && language === 'russian') &&
				<span>школы {storage.read('school')} (код-{storage.read('school_id')})</span>
			}
			{(role === 'librarian' && (language === 'kyrgyz' || language === 'uzbek' || language === 'tajik')) &&
				<span>{storage.read('school')} мектеби ({storage.read('school_id')} - коду)</span>
			}
			<br />
			<span>{formattedDate} </span>
			<br />
		</div>
	)
}