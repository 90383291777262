import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {D10SubReg, D10SubRepub, StudentAmountList} from "../feature/report";
import {Layout} from "../app/layout";
import {usePageTitle} from "../hooks";
import {isDistObserver, isEdu_min, isJanyKitep, isLabrarianOrDirector, isRegObserver} from '../lib/helper';
import {D10DistAll} from "../feature/report/district/d10-dist-item-all";
import {G1RentedBooks} from "../feature/report/rented-books/g-1-rented-books";
import {G2LibraryRooms} from "../feature/report/library-rooms/g-2-library-rooms";
import {G3Beneficiaries} from "../feature/report/beneficiaries/g-3-beneficiaries";
import {G3BeneficiariesDistrict} from "../feature/report/beneficiaries/g-3-beneficiaries-district";
import {G2LibraryRoomsDist} from "../feature/report/library-rooms/g-2-library-rooms-dist";
import {G1RentedBooksDist} from "../feature/report/rented-books/g-1-rented-books-dist";
import {G1RentedBooksReg} from "../feature/report/rented-books/g-1-rented-book-reg";
import {G2LibraryRoomsReg} from "../feature/report/library-rooms/g-2-library-rooms-reg";
import {G3BeneficiariesReg} from "../feature/report/beneficiaries/g-3-beneficiaries-reg";
import {D10DistItemResults} from "../feature/report/district/d10-dist-item-resuilts";
import './report.css';
import {G4RbSchool} from "../feature/report/recommended-books/g4-rb-school";
import {G4RbDist} from "../feature/report/recommended-books/g4-rb-dist";
import {G4RbReg} from "../feature/report/recommended-books/g4-rb-reg";
import {D10} from "../feature/report/d10/D10";
import {storage} from "../service";
import {D10Region} from "../feature/report/region/d10-region";
import {G2LibraryRoomsJK} from "../feature/report/library-rooms/g2-library-rooms";
import {G3BeneficiariesJK} from "../feature/report/beneficiaries/g-3-beneficiaries-jk";
import {G4Rb} from "../feature/report/recommended-books/g4-rb";
import {G5GivenBooks} from "../feature/report/given-books/g-5-given-books";
import {A1ActiveSchools} from "../feature/report/active-schools/a-1-active-school";
import {B1FillingReport} from "../feature/report/filling-report/b1-filling-report";
import {B1FillingReportDist} from "../feature/report/filling-report/b1-filling-report-dist";
import {B1FillingReportRegion} from "../feature/report/filling-report/b1-filling-report-reg";
import { G6ContingentReportRegion } from "../feature/report/g6-contingent/g6-contingent-report-reg";
import { G7ShoolEventsReportLibrarian } from "../feature/report/g7-school-events/g7-school-events-report-lib";

export const Report = ({quer}) => {
    const {t} = useTranslation()
    usePageTitle(t('report.title'))

    const school_id = storage.read('school_id')
    return (
        <Layout>
            <h2 className="mb-4 report-title">{t('report.title')}</h2>
            <></>
            <Tabs
                defaultActiveKey="d10Sub"
                transition={false}
                className="m-auto tabs"
                variant='pills'
            >
                <Tab eventKey="d10Sub" title={t('report.d10.title')}>
                    {isLabrarianOrDirector && <D10 eventKey="d10" initQuery={{
                        'language': 'kyrgyz',
                        'part': 1,
                        'school_id': `${school_id}`
                    }}/>}
                    {isDistObserver && <D10DistAll eventKey="d10dist"/>}
                    {isRegObserver && <D10SubReg eventKey="d10reg"/>}
                    {isJanyKitep && <D10SubRepub eventKey="d10rep"/>}
                    {isEdu_min && <D10SubRepub eventKey="d10rep"/>}
                </Tab>
                {isLabrarianOrDirector && <Tab eventKey="show-class" title={t('report.show-class.title')}>
                    <StudentAmountList/>
                </Tab>}
                {/* {isLabrarianOrDirector && <Tab eventKey="g1" title={t('report.g1.title')}>
                    <G1RentedBooks/>
                </Tab>} */}
                {isLabrarianOrDirector && <Tab eventKey="g2" title={t('report.g2.title')}>
                    <G2LibraryRooms/>
                </Tab>}
                {isLabrarianOrDirector && <Tab eventKey="g3" title={t('report.g3.title')}>
                    <G3Beneficiaries/>
                </Tab>}
                {isDistObserver && <Tab eventKey="g1-dist" title={t('report.g1.title')}>
                    <G1RentedBooksDist/>
                </Tab>}
                {isDistObserver && <Tab eventKey="g2-dist" title={t('report.g2.title')}>
                    <G2LibraryRoomsDist/>
                </Tab>}
                {isDistObserver && <Tab eventKey="g3-dist" title={t('report.g3.title')}>
                    <G3BeneficiariesDistrict/>
                </Tab>}

                {isDistObserver && <Tab eventKey="d10-results" title={t('report.d10.results-title')}>
                    <D10DistItemResults/>
                </Tab>}

                {isRegObserver && <Tab eventKey="g1-reg" title={t('report.g1.title')}>
                    <G1RentedBooksReg/>
                </Tab>}
                {isRegObserver && <Tab eventKey="g2-reg" title={t('report.g2.title')}>
                    <G2LibraryRoomsReg/>
                </Tab>}
                {isRegObserver && <Tab eventKey="g3-reg" title={t('report.g3.title')}>
                    <G3BeneficiariesReg/>
                </Tab>}
                {isLabrarianOrDirector && <Tab eventKey="g4" title={t('report.g4.title')}>
                    <G4RbSchool/></Tab>}
                {isDistObserver && <Tab eventKey="g4" title={t('report.g4.title')}>
                    <G4RbDist/>
                </Tab>}
                {isRegObserver && <Tab eventKey="g4" title={t('report.g4.title')}>
                    <G4RbReg/>
                </Tab>}

                {isRegObserver && <Tab eventKey="d10-results" title={t('report.d10.results-title')}>
                    <D10Region/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="g1-rented-books" title={t('report.g1.title')}>
                    <G1RentedBooks/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="g2-library-rooms" title={t('report.g2.title')}>
                    <G2LibraryRoomsJK/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="g3-beneficiaries" title={t('report.g3.title')}>
                    <G3BeneficiariesJK/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="g4-recommended-books" title={t('report.g4.title')}>
                    <G4Rb/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="g1-active-school" title={t('report.a1.title')}>
                    <A1ActiveSchools/>
                </Tab>}
                {isJanyKitep && <Tab eventKey="b1-filling-report" title={t('report.b1.title')}>
                    <B1FillingReport/>
                </Tab>}
                {isLabrarianOrDirector && <Tab eventKey="g5-given-books" title={t('report.g5.title')}>
                    <G5GivenBooks/>
                </Tab>}
                {isRegObserver && <Tab eventKey="b1-filling-report" title={t('report.b1.title')}>
                    <B1FillingReportRegion/>
                </Tab>}
                {isDistObserver && <Tab eventKey="b1-filling-report" title={t('report.b1.title')}>
                    <B1FillingReportDist/>
                </Tab>}
                {isRegObserver && <Tab eventKey="g6-contingent-report" title={t('report.g6.title')}>
                    <G6ContingentReportRegion place='region'/>
                </Tab>}
                {isDistObserver && <Tab eventKey="g6-contingent-report" title={t('report.g6.title')}>
                    <G6ContingentReportRegion place='district'/>
                </Tab>}
                {isLabrarianOrDirector && <Tab eventKey="g7-school-events" title={t('report.g7.title')}>
                    <G7ShoolEventsReportLibrarian/>
                </Tab>}
                {isEdu_min && <Tab eventKey="g1-rented-books" title={t('report.g1.title')}>
                    <G1RentedBooks/>
                </Tab>}
                {isEdu_min && <Tab eventKey="g2-library-rooms" title={t('report.g2.title')}>
                    <G2LibraryRoomsJK/>
                </Tab>}
                {isEdu_min && <Tab eventKey="g3-beneficiaries" title={t('report.g3.title')}>
                    <G3BeneficiariesJK/>
                </Tab>}
                {isEdu_min && <Tab eventKey="g4-recommended-books" title={t('report.g4.title')}>
                    <G4Rb/>
                </Tab>}
                {isEdu_min && <Tab eventKey="g1-active-school" title={t('report.a1.title')}>
                    <A1ActiveSchools/>
                </Tab>}
                {isEdu_min && <Tab eventKey="b1-filling-report" title={t('report.b1.title')}>
                    <B1FillingReport/>
                </Tab>}
            </Tabs>
        </Layout>
    )
}
